import apiCall from './apiCall'

import {Endpoints} from "@/definitions/api/api";

export async function fetchData<T extends keyof Endpoints>(url: T, data: Endpoints[T]['request'], errorMessage = 'failed to fetch data'):Promise<Endpoints[T]['response']> {
  const res = await apiCall(url, data)
  const returnData = await res.json()
  if (res.status === 401) { // todo - document this and create ticket for unifiing this "Access denied." logic
    // todo - hardcoded message to handle cases where api access is not allowed
    if (returnData === 'Access denied.' || returnData?.error === 'page.401.redirect') {
      window.location = 'https://langoid.com/login?redirect=' + location.href as any
    }
    return new Promise(() => {
      return {error: 'Access to this page is not allowed'}
    })
  }
  if (res.ok) {
    return returnData
  }

  throw new Error(errorMessage + ' ReturnData: ' + JSON.stringify(returnData))
}
