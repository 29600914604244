<script lang='ts'>
  import { onMount } from 'svelte';
  import { link, navigate } from 'svelte-routing';

  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/Breadcrumb.svelte'

  export let iso = '';
  export let courseId: string;
  export let moduleId: string;
  export let id: string;


  const options: any = {}
  let quill:any

  let lesson = {
    content: '',
    duration: 0,
    orderIndex: 0,
    title: '',
    videoUrl: ''
  };

  const selectedLanguage = languages.filter(el => el.iso === iso)[0].id;

  import('@/kit/svelteQuill').then((el: any) => {
    quill = el.quill
  })


  const setContent = (e: CustomEvent) => {
    lesson.content = e.detail.html
  }

  onMount(async () => {
    if (id !== 'new') {
      await loadLesson();
    }
  });

  const loadLesson = async () => {
    try {
      const data = await fetchData('modcourse/list', {
        id,
        idLanguage: selectedLanguage,
        idModule: moduleId
      });

      if (!data.lesson) {
        notifier.warning('Lesson not found');
        return;
      }

      const { title, content, video_url: videoUrl, duration, order_index: orderIndex } = data.lesson;
      lesson = {
        content,
        duration,
        orderIndex,
        title,
        videoUrl
      };
      setTimeout(() => {
        const editorElement = document.querySelector('.ql-editor')
        if (editorElement) {
          editorElement.innerHTML = lesson.content
        }
      }, 200)
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to load lesson');
    }
  };

  const handleSubmit = async (event: Event) => {
    event.preventDefault();
    try {
      const method = id === 'new' ? 'saveLesson' : 'updateLesson';
      const data = {
        content: lesson.content || '',
        duration: parseInt(lesson.duration) || 0,
        id: id !== 'new' ? parseInt(id) : undefined,
        idModule: parseInt(moduleId),
        orderIndex: parseInt(lesson.orderIndex) || 0,
        title: lesson.title || '',
        videoUrl: lesson.videoUrl || ''
      }

      const response = await fetchData(`modcourse/${method}`, data);
      if (response.error) {
        notifier.warning(response.error);
      } else {
        notifier.success('Lesson saved successfully');
        navigate(`/${iso}/courses/${courseId}/modules/${moduleId}`)
      }
    } catch (error) {
      console.error(error);
      notifier.warning('Failed to save lesson');
    }
  };
</script>

<div class="lesson-management">
  <Breadcrumb />
  <div class="header">
    <h1>{id === 'new' ? 'Add New Lesson' : 'Edit Lesson'}</h1>
  </div>

  <div class="content">
    <form class="lesson-info" on:submit={handleSubmit}>
      <div class="form-group">
        <label for="title">Title</label>
        <input id="title" required type="text" bind:value={lesson.title} name="title" />
      </div>

      <div class="form-group">
        <label for="video_url">Video URL</label>
        <input id="video_url" type="url" bind:value={lesson.videoUrl} name="video_url" />
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="duration">Duration (minutes)</label>
          <input id="duration" type="number" bind:value={lesson.duration} name="duration" />
        </div>

        <div class="form-group">
          <label for="order_index">Order</label>
          <input id="order_index" type="number" bind:value={lesson.orderIndex} name="order_index" />
        </div>
      </div>

      <div class="form-group">
        <label for="content">Content</label>
        <!-- <textarea id="content" required name="content">{lesson.content}</textarea> -->
        {#if quill}
          <div class='editor' on:text-change={setContent} use:quill={options}/>
        {/if}
      </div>

      <div class="form-actions">
        <button class="primary" type="submit">{id === 'new' ? 'Create Lesson' : 'Update Lesson'}</button>
        <a class="button" href="/{iso}/courses/{courseId}/modules/{moduleId}" use:link>Back to Module</a>
      </div>
    </form>
  </div>
</div>

<style lang="scss">
  .lesson-management {
    padding: 2rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .content {
    max-width: 64rem;
  }

  .lesson-info {
    padding: 2rem;
    background: var(--white-background);
    border-radius: 0.4rem;
  }

  .form-group {
    margin-bottom: 1.4rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
    }

    // textarea,
    input[type="text"],
    input[type="number"] {
      width: 100%;
      padding: 0.5rem;
      border: solid var(--gray-3) 0.1rem;
      border-radius: 0.4rem;
    }

    // textarea {min-height: 19.2rem;}
  }

  .form-actions {
    display: flex;
    gap: 1rem;
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
</style>
