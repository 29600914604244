<script lang='ts'>
  import { link } from 'svelte-routing'

</script>
{#if location.pathname.split('/')[1]?.length === 3}
  {@const segment2 = location.pathname.split('/')?.[2] || ''}
  <a href="/{location.pathname.split('/')[1]}" use:link>{location.pathname.split('/')[1]}</a>
  {#if segment2}
    <span>/</span>
    {#if segment2}
      <a href="/{location.pathname.split('/')[1]}/{segment2}" use:link>{segment2}</a>
    {:else}
      <span>{segment2}</span>
    {/if}
  {/if}
{/if}
