<script lang='ts'>
  export let color = 'currentColor'
  export let size = '24'
  export let weight = 'duotone'
</script>

<svg fill={color}
  height={size}
  viewBox='0 0 256 256'
  width={size}
  xmlns='http://www.w3.org/2000/svg'>
  <rect fill='none' height='256' width='256' />
  {#if weight === 'duotone'}
    <path d='M216,56V200a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V56A16,16,0,0,1,56,40H200A16,16,0,0,1,216,56Z' opacity='0.2' /><path
      d='M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z'
    />
  {:else if weight === 'fill'}
    <path
      d='M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM184,136H136v48a8,8,0,0,1-16,0V136H72a8,8,0,0,1,0-16h48V72a8,8,0,0,1,16,0v48h48a8,8,0,0,1,0,16Z'
    />
  {:else if weight === 'regular'}
    <path d='M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z' />
  {:else if weight === 'thin'}
    <path d='M220,128a4,4,0,0,1-4,4H132v84a4,4,0,0,1-8,0V132H40a4,4,0,0,1,0-8h84V40a4,4,0,0,1,8,0v84h84A4,4,0,0,1,220,128Z' />
  {/if}
</svg>

<style>
  svg {
    pointer-events: none;
  }
</style>
