<script lang='ts'>
  import { link } from 'svelte-routing'

  import { hasLevel } from '@/helpers/game'

  import Breadcrumb from '@/components/Breadcrumb.svelte'
  import LanguageDoc from '@/components/docs/LanguageDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  export let iso = ''
  document.title = `Language - ${iso}`
</script>
<div class='page-wrapper'>
  <Breadcrumb />
  <div class='language-page'>

    <ul class:hidden={!hasLevel(1)}>
      <li class:hidden={!hasLevel(2)}>
        <h2>Words</h2>
        <ul>
          <li>
            <a href={`/${iso}/words/add-word-examples`} use:link>Add word examples</a>
          </li>
          <li>
            <a href={`/${iso}/words/translate-word`} use:link>Translate word</a>
          </li>
          <li>
            <a href={`/${iso}/word-groups`} use:link>Word groups</a>
          </li>
        </ul>
      </li>
      <li>
        <h2>Lesson</h2>
        <ul>
          {#if iso !== 'eng'}
            <li>
              <a href={`/${iso}/lesson/translate`} use:link>Translate</a>
            </li>
            <li>
              <a href={`/${iso}/lesson/approve-translation`} use:link>Approve translation</a>
            </li>
          {/if}
          <li>
            <a href={`/${iso}/lesson/pos-tagging`} use:link>POS tagging</a>
          </li>
        </ul>
      </li>
      <li class:hidden={!hasLevel(4)}>
        <h2>CRUD</h2>
        <ul>
          <li>
            <a href={`/${iso}/crud/words`} use:link>Words</a>
          </li>
          <li>
            <a href={`/${iso}/crud/sentences`} use:link>Sentences</a>
          </li>
          <li>
            <a href={`/${iso}/crud/lessons`} use:link>Lessons</a>
          </li>
          <li>
            <a href={`/${iso}/crud/wif-templates`} use:link>WIF templates</a>
          </li>
          <li>
            <a href={`/${iso}/crud/wifs`} use:link>WIFS</a>
          </li>
          <li>
            <a href={`/${iso}/crud/wif-html`} use:link>WIF HTML</a>
          </li>
          <li class:hidden={!hasLevel(10)}>
            <a href={`/${iso}/crud/word-sentences`} use:link>JOINS demo</a>
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>
        <h2>Audio</h2>
        <ul>
          <li>
            <a href={`/${iso}/mix/add-audio-sentence`} use:link>Add audio sentence</a>
          </li>
          <li>
            <a href={`/${iso}/mix/add-audio-word`} use:link>Add audio word</a>
          </li>
        </ul>
      </li>
      <li class:hidden={!hasLevel(6)}>
        <h2>Admin</h2>
        <ul>
          <li>
            <a href={`/${iso}/admin/wif-assign`} use:link>WIF assign</a>
          </li>
          <li>
            <a href={`/${iso}/admin/wif-check`} use:link>WIF check</a>
          </li>
        </ul>
      </li>
      <li class:hidden={!hasLevel(2)}>
        <h2>Grammar</h2>
        <ul>
          <li>
            <a href={`/${iso}/admin/dev/word-forms`} use:link>Word forms</a>
          </li>
          <li>
            <a href={`/${iso}/admin/grammar/rules`} use:link>Rules writing</a>
          </li>
          <li class:hidden={!hasLevel(6)}>
            <a href={`/${iso}/crud/grammar-rules`} use:link>Manage rules</a>
          </li>
          <li>
            <a href={`/${iso}/admin/grammar/rules-order`} use:link>Rules order</a>
          </li>
          <li>
            <a href={`/${iso}/admin/grammar/wifs`} use:link>WIFs</a>
          </li>
        </ul>
      </li>

      <li class:hidden={!hasLevel(2)}>
        <h2>IPA & Reading</h2>
        <ul>
          <li>
            <a href={`/${iso}/ipa/create-reading-tutorial`} use:link>Create reading tutorial</a>
          </li>
          <li>
            <a href={`/${iso}/ipa/ipa-word`} use:link>IPA word</a>
          </li>
        </ul>
      </li>
      <li class:hidden={!hasLevel(2)}>
        <h2>Courses</h2>
        <ul>
          <li>
            <a href={`/${iso}/courses`} use:link>Courses</a>
          </li>
        </ul>
      </li>

    </ul>
  </div>
</div>
<Documentation dateCompleted='2023-08-26'>
  <LanguageDoc />
</Documentation>
<style lang='scss'>
  .language-page {
    display: flex;
  }

  h2 {
    margin-bottom: 0;
    font-size: 2rem;
  }

  ul {
    padding-left: 0;

    /* stylelint-disable-next-line rscss/no-descendant-combinator */
    > li {
      list-style: none;

      > a {
        display: inline-block;
        padding: 0.4rem 1rem 0.4rem 0;
        font-size: 1.4rem;
      }
    }
  }

  /* stylelint-disable-next-line */
  .hidden {
    display: none;
  }
</style>
