<script lang='ts'>
  import { onMount } from 'svelte';
  import { link } from 'svelte-routing';

  import languages from '@/config/languages';

  import { fetchData } from '@/helpers/fetchHelpers';
  import { notifier } from '@/helpers/notifier';

  import Breadcrumb from "@/components/Breadcrumb.svelte";

  export let iso = ''
  let courses: any[] = [];

  onMount(async () => {
    const selectedLanguage = languages.filter(el => el.iso === iso)[0].id;
    if (selectedLanguage) {
      await loadCourses(selectedLanguage);
    }
  })

  const loadCourses = async (language: number) => {
    try {
      const data = await fetchData('modcourse/list', { idLanguage: language });
      courses = data.courses;
    } catch (error) {
      console.error(error);
      notifier.warning('An error occurred while fetching courses');
    }
  }
</script>

<div class="courses-page">
  <Breadcrumb />
  <div class="header">
    <h1>Courses</h1>
    <a class="button" href="/{iso}/courses/new" use:link>Add New Course</a>
  </div>

  <div class="courses-list">
    {#if courses.length}
      {#each courses as course}
        <div class="course-item">
          {#if course.image}
            <img alt={course.title} src={course.image} />
          {/if}
          <div class="course-info">
            <h3><a href="/{iso}/courses/{course.id}" use:link>#{course.id} {course.title}</a></h3>
            <p>{course.description || 'No description'}</p>
          </div>
        </div>
      {/each}
    {:else}
      <p>No courses available for this language.</p>
    {/if}
  </div>
</div>

<style lang="scss">
  .courses-page {
    padding: 2rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .courses-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    gap: 2rem;
  }

  .course-item {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    background: var(--white-background);
    border-radius: 0.4rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);

    img {
      object-fit: cover;
      width: 9.6rem;
      height: 9.6rem;
      border-radius: 0.2rem;
    }

    .course-info {
      flex: 1;

      h3 {
        margin: 0 0 0.5rem;
      }

      p {
        margin: 0;
        color: var(--gray-6);
      }
    }
  }
</style>
